import router from '@/router/index';
import MetaService from "@/services/resources/MetaService";
import MemberService from "@/services/resources/MemberService";
import CourseService from "@/services/resources/CourseService";
//aux functions
const verifyIfPercentageTargetIsReached = (percentage, target, currentLesson) => {
  if (currentLesson.is_completed) return true;
  if (target === 100) target -= 1;
  return percentage >= target;
}
const playersKindCanGetTime = ['youtube', 'vimeo', 'panda'];

const playerLimitationByPercentageIsActive = (getters) => {
  const playerLimitationByPercentage = getters.getPlayerConfig.limitMinimumPercentageLessonIsActive;
  const playersKindCanGetTime = verifyPlayersKindCanGetTime(getters.getCurrentLesson.mediaType);
  const hasLimitationByPercentage = playerLimitationByPercentage && playersKindCanGetTime;
  const limitationHasReached = verifyIfPercentageTargetIsReached(getters.getCurrentLesson.percent, getters.getPlayerConfig.minimumPercentageToCompleteLesson, getters.getCurrentLesson);
  if (hasLimitationByPercentage && !limitationHasReached) {
    return false;
  }
  return true;
}

const verifyPlayersKindCanGetTime = (kind) => {
  if (!kind) return false;
  return playersKindCanGetTime.includes(kind);
}
const hasNextModule = (currentModule, AllModules) => {
  const currentModuleIndex = AllModules.findIndex((module) => module.id === currentModule.id);
  return !!AllModules[currentModuleIndex + 1];
}
const hasPrevModule = (currentModule, AllModules) => {
  const currentModuleIndex = AllModules.findIndex((module) => module.id === currentModule.id);
  return currentModuleIndex !== 0;
}
const processNextModule = (currentModule, AllModules) => {
  if (!hasNextModule(currentModule, AllModules)) return {};
  const currentModuleIndex = AllModules.findIndex((module) => module.id === currentModule.id);
  return AllModules[currentModuleIndex + 1] || {};
}
const processPrevModule = (currentModule, AllModules) => {
  const currentModuleIndex = AllModules.findIndex((module) => module.id === currentModule.id);
  if (currentModuleIndex === 0) {
    return {};
  }
  return AllModules[currentModuleIndex - 1] || {};
}
const hasNextLesson = (currentLesson, AllLessons) => {
  const currentLessonIndex = AllLessons.findIndex((lesson) => lesson.id === currentLesson.id);
  return !!AllLessons[currentLessonIndex + 1];
}
const processNextLesson = (currentLesson, AllLessons) => {
  if (!hasNextLesson(currentLesson, AllLessons)) return {};
  const currentLessonIndex = AllLessons.findIndex((lesson) => lesson.id === currentLesson.id);
  return AllLessons[currentLessonIndex + 1] || {};
}
const processPrevLesson = (currentLesson, AllLessons) => {
  const currentLessonIndex = AllLessons.findIndex((lesson) => lesson.id === currentLesson.id);
  if (currentLessonIndex === 0) {
    return {};
  }
  return AllLessons[currentLessonIndex - 1];
}
const hasPrevLesson = (currentLesson, AllLessons) => {
  const currentLessonIndex = AllLessons.findIndex((lesson) => lesson.id === currentLesson.id);
  return currentLessonIndex > 0;
}
const processCurrentLesson = (currentModuleLessons, idLessonUrl) => {
  let currentLesson = {};
  let findedLesson = false;
  if (idLessonUrl) {
    currentModuleLessons.map((lesson) => {
      if (lesson.id == idLessonUrl && !findedLesson) {
        currentLesson = lesson;
        findedLesson = true;
      }
    });
  } else {
    currentModuleLessons.map((lesson) => {
      if (!lesson.is_completed && !findedLesson) {
        currentLesson = lesson;
        findedLesson = true;
      }
    });
  }
  
  if (!findedLesson) {
    currentLesson = currentModuleLessons[0] || {};
  }
  const isPassiveForLimitationByPercentage = verifyPlayersKindCanGetTime(currentLesson.mediaType);
  
  let lesson = currentLesson;

  if(!isPassiveForLimitationByPercentage) {
    lesson.percent = 100;
  }
  
  return lesson;
}
const processHasNextLessonForContinue = (AllLessons, currentLesson) => {
  let nextFinded = false;
  AllLessons.map((lesson) => {
    if (!lesson.is_completed && lesson.id !== currentLesson.id && !nextFinded) {
      nextFinded = lesson;
    }
  });
  return !!nextFinded;
}
const processNextLessonForContinue = (AllLessons, currentLesson) => {
  const findedLessons = AllLessons.filter(lesson => !lesson.is_completed && lesson.id !== currentLesson.id)
  return findedLessons[0] || {};
};

export default {
  loadPlayerFromUrl: async ({ commit, dispatch, getters }, especificModuleID) => {
    commit('setCurrentLessonComments', []);
    commit('setPlayerStatus', 'loading');
    let idCourse = router.app._route.params.curso;
    let idModule = especificModuleID || router.app._route.params.modulo;    
    let hasModuleId = idModule ? `&current_module_id=${idModule}` : '';
    const serviceCourse = CourseService.build();
    await serviceCourse
    .read(`/${idCourse}/watch?data[]=course&data[]=modules&data[]=currentModule&data[]=currentModuleLessons&data[]=currentLesson${hasModuleId}`)
    .then((resp) => {
      //treat course
      commit('setCurrentCourse', resp.course);
      
      //treat modules and current module
      commit('setCurrentModule', resp.currentModule);
      commit('setCurrentModules', resp.modules);
      commit('setPrevModule', processPrevModule(resp.currentModule, resp.modules));
      commit('setNextModule', processNextModule(resp.currentModule, resp.modules));
      commit('setHasNextModule', hasNextModule(resp.currentModule, resp.modules));
      commit('setHasPrevModule', hasPrevModule(resp.currentModule, resp.modules));
      commit('setCurrentModuleLessons', resp.currentModuleLessons);

      dispatch('updateClickedLesson', resp)
    })
    .catch((err) => console.error(err))
  },
  updateClickedLesson({commit, getters, dispatch}, resp) {
    const idLessonUrl = router.app._route.params.aula;
    const course = resp ? resp.course : getters.getCurrentCourse
    const currentModule = resp ? resp.currentModule : getters.getCurrentModule
    const currentModuleLessons = resp ? resp.currentModuleLessons : getters.getCurrentModuleLessons
    const currentLesson = processCurrentLesson(currentModuleLessons, idLessonUrl);
    const page = getters.getLessonCommentsCurrentPage;

    //treat lessons and current lesson
    commit('setCurrentLesson', { ...currentLesson, percent: 0 });
    commit('setHasNextLesson', hasNextLesson(currentLesson, currentModuleLessons));
    commit('setHasPrevLesson', hasPrevLesson(currentLesson, currentModuleLessons));
    commit('setPrevLesson', processPrevLesson(currentLesson, currentModuleLessons));
    commit('setNextLesson', processNextLesson(currentLesson, currentModuleLessons));
    commit('setNextLessonForContinue', processNextLessonForContinue(currentModuleLessons, currentLesson));
    commit('setHasNextLessonForContinue', processHasNextLessonForContinue(currentModuleLessons, currentLesson));
    
    //treat player
    commit('setPlayerStatus', 'playing');
    dispatch('loadLessonComments', {
      course: course.id,
      module: currentModule.id,
      lesson: currentLesson.id,
      page
    });
    dispatch('loadPlayerConfig');

    //updateUrl
    let chunkCourse = `/curso/${course.id}`;
    let chinkModule = `/modulo/${currentModule.id}`;
    let chunkLesson = currentLesson.id ? `/aula/${currentLesson.id}` : ``;
    let route = `${chunkCourse}${chinkModule}${chunkLesson}`;
    
    commit('setResetCurrentLessonComments', []);
    router.push(route);
  },
  updateRatingState({commit}, rating) {
    commit('updateLessonRating', { rating });
  },
  loadPlayerConfig({ commit }) {
    const serviceMeta = MetaService.build();
    let payload = {};
    serviceMeta
      .search("" +
        "keys[]=coment_lesson&" +
        "keys[]=limit_percetage_lesson&" +
        "keys[]=number_limit_percetage_lesson&" +
        "keys[]=complete_lessson_next&" +
        "keys[]=disable_comments_courses"
      )
      .then((res) => {
        payload.commentsIsActive = res.coment_lesson === "off" ? false : true;
        payload.limitMinimumPercentageLessonIsActive = res.limit_percetage_lesson === "off" || !res.limit_percetage_lesson ? false : true;
        payload.minimumPercentageToCompleteLesson = !res.number_limit_percetage_lesson ? 0 : parseInt(res.number_limit_percetage_lesson);
        //conclui a aula automaticamente ao clicar em próximo
        payload.concludeWhenNextIsClicked = res.complete_lessson_next === "off" ? false : true;
        
        let hasCoursesWithCommentUnactivated = res.disable_comments_courses;
        if (hasCoursesWithCommentUnactivated) {
          const coursesParseds = JSON.parse(res.disable_comments_courses);
          payload.disable_comments_courses = coursesParseds
        } else {
          payload.disable_comments_courses = [];
        }

        commit('setPlayerConfig', payload);
      })
      .catch((err) => console.error(err));
  },
  loadCourse: async ({ commit }) => {
    let idCourse = router.app._route.params.curso;

    const serviceCourse = CourseService.build();
    await serviceCourse
      .read(`/${idCourse}/watch?data[]=course`)
      .then(async(res) => {
        await commit('setCurrentCourse', res.course);
      })
      .catch((err) => console.error(err));
  },
  sendToNextLesson: ({ dispatch, getters }) => {
    const playerLimitationByPercentage = playerLimitationByPercentageIsActive(getters);
    const limitationIsActiveAndPercentageIsNotReached = !playerLimitationByPercentage;
    if (limitationIsActiveAndPercentageIsNotReached) return;

    if (getters.getHasNextLesson) {
      dispatch('goToEspecificLesson', {especificLesson: getters.getNextLesson, isLessonClick: false});
    }
    
    if (!getters.getHasNextLesson && getters.getHasNextModule) {
      if (!getters.getNextModule.is_liberated) return; // modulo não liberado
      dispatch('goToEspecificModule', getters.getNextModule);
    }
  },
  sendToPrevLesson: ({ getters, dispatch }) => {
    if (getters.getHasPrevLesson) {
      dispatch('goToEspecificLesson', {especificLesson: getters.getPrevLesson, isLessonClick: false});
    }

    if (!getters.getHasPrevLesson && getters.getHasPrevModule) {
      dispatch('goToEspecificModule', getters.getPrevModule);
    }
  },
  goToEspecificModule: async ({ getters, dispatch, commit }, especificModule) => {
    const playerLimitationByPercentage = playerLimitationByPercentageIsActive(getters);
    const limitationIsActiveAndPercentageIsNotReached = !playerLimitationByPercentage;
    const hasLimitationByPercentage = limitationIsActiveAndPercentageIsNotReached;
    const isNotTheNextModule = getters.getNextModule.id !== especificModule.id
   
    if (hasLimitationByPercentage && isNotTheNextModule) return;

    if (!especificModule.is_liberated) return;
    commit('setResetCurrentLessonComments', []);
    router.push('/curso/' + router.app._route.params.curso + '/modulo/' + especificModule.id);
    await dispatch('loadPlayerFromUrl', especificModule.id);
  },
  validateModuleToEspecificLesson: async ({ getters, dispatch, commit }, {especificLesson, isLessonClick}) => {

    if(especificLesson.module_id === getters.getCurrentModule.id){
      await dispatch('goToEspecificLesson', {especificLesson: especificLesson, isLessonClick: false});
    }else {
      const module = getters.getCurrentModules.find(x => x.id === especificLesson.module_id)
      await dispatch('goToEspecificModule', module);
      await dispatch('goToEspecificLesson', {especificLesson: especificLesson, isLessonClick: false});
    }
  },
  goToEspecificLesson: ({ getters, dispatch, commit }, {especificLesson, isLessonClick}) => {

    if(!especificLesson) return;

    const playerLimitationByPercentage = playerLimitationByPercentageIsActive(getters);
    const limitationIsActiveAndPercentageIsNotReached = !playerLimitationByPercentage;
    const hasLimitationByPercentage = limitationIsActiveAndPercentageIsNotReached;
    const isNotTheNextLesson =  getters.getNextLessonForContinue && getters.getNextLessonForContinue.id && getters.getNextLessonForContinue.id !== especificLesson.id
    const lessonIsNotCompleted = !especificLesson.is_completed;
    const blockedByLimitationPercentage = (hasLimitationByPercentage && isNotTheNextLesson && lessonIsNotCompleted)
    
    if (!especificLesson.is_liberated) {
      const lessonModule = especificLesson.module_id || router.app._route.params.modulo;

      setTimeout(() => {
        commit('setResetCurrentLessonComments', []);
        router.push('/curso/' + router.app._route.params.curso + '/modulo/' + lessonModule + '/aula/' + especificLesson.id);
        if(!isLessonClick) dispatch('loadPlayerFromUrl');
        else dispatch('updateClickedLesson')
      }, 300);
    } else {
      if (blockedByLimitationPercentage) return;
  
      const mustMarkAsCompleted = getters.getPlayerConfig.concludeWhenNextIsClicked;
  
      if (mustMarkAsCompleted && !especificLesson.marked_as_completed && especificLesson.id === getters.getNextLesson.id && especificLesson.is_liberated) {
        dispatch('markLesson', getters.getCurrentLesson);
      }
  
      const lessonModule = especificLesson.module_id || router.app._route.params.modulo;
  
      setTimeout(() => {
        commit('setResetCurrentLessonComments', []);
        router.push('/curso/' + router.app._route.params.curso + '/modulo/' + lessonModule + '/aula/' + especificLesson.id);
        if(!isLessonClick) dispatch('loadPlayerFromUrl');
        else dispatch('updateClickedLesson')
      }, 300);
    }

  },
  loadLessonComments: ({ commit }, payload) => {
    commit('setLessonCommentsLoading', true)
    const serviceCourse = CourseService.build();
    let idCourse = payload.course;
    let idModule = payload.module;
    let idLesson = payload.lesson;

    if (idCourse && idModule && idLesson) {
      serviceCourse
        .read(`${idCourse}/module/${idModule}/lesson/${idLesson}/comment?page=${payload.page}`)
        .then((res) => {
          if(payload?.isComment === true){
            commit('setResetCurrentLessonComments', [])
          }
          commit('setCurrentLessonComments', res.data)
          commit('setPlayerStatus', 'playing');
          commit('setLessonCommentsCurrentPage', res.current_page)
          commit('setLessonCommentstotalPage', res.last_page)
        })
        .catch((err) => console.error(err))
        .finally(() => {
          commit('setLessonCommentsLoading', false)
        });
    } else {
      commit('setCurrentLessonComments', []);
      commit('setLessonCommentsLoading', false)
    }
  },
  markLesson: async ({ commit, getters }, lesson) => {
    if (!lesson.is_liberated) return;
    await commit('setPlayerStatus', 'loading');
    const currentLesson = getters.getCurrentLesson;
    const serviceCourse = CourseService.build();
    const moduleId = getters.getCurrentModule.id;
    const lessonId = (lesson && lesson.id) ? lesson.id : currentLesson.id;
    const payload = {
      id: `${getters.getCurrentCourse.id}/module/${moduleId}/lesson/${lessonId}/progress`,
      completed: !currentLesson.is_completed,
    }
    await serviceCourse
      .postID(payload)
      .then(async() => {        
        let newLesson = !currentLesson.is_completed;
        let newModule = currentLesson.is_completed ? +1 : -1;
        let newModuleLessons = {
          id: lessonId,
          completed: newLesson,
        }   
        let newModuleAndId = {
          id: moduleId,
          value: newModule
        }
        await commit('setCompletedCurrentLesson', newLesson);
        await commit('setCompletedCurrentModuleLessons', newModuleLessons);
        await commit('setCountCurrentModules', newModuleAndId);
        await commit('setCountCurrentCourse', newModule);
        await commit('setPlayerStatus', 'playing');
      })
      .catch((err) => console.error(err))
  },
  makeAComment: ({ commit, getters, dispatch }, payload) => {
    commit('setPlayerStatus', 'loading');
    const serviceCourse = CourseService.build();
    const courseId = getters.getCurrentCourse.id;
    const moduleId = getters.getCurrentModule.id;
    const lessonId = getters.getCurrentLesson.id;
    const page = getters.getLessonCommentsCurrentPage;
    let data = {
      id: `${courseId}/module/${moduleId}/lesson/${lessonId}/comment`,
      comment: payload.comment
    }
    if (payload.parent_lesson_comment_id) {
      data.parent_lesson_comment_id = payload.parent_lesson_comment_id;
    }
    serviceCourse
      .postID(data)
      .then(() => {
        dispatch('loadLessonComments', {
          course: courseId,
          module: moduleId,
          lesson: lessonId,
          page: page,
          isComment: true
        });
      })
      .catch((err) => console.error(err))
  },
  toggleLike: ({ commit, getters, dispatch }, {comment, parentId}) => {
    const courseId = getters.getCurrentCourse.id;
    const moduleId = getters.getCurrentModule.id;
    const lessonId = getters.getCurrentLesson.id;

    var data = {
      id: '/'+courseId+'/module/'+moduleId+'/lesson/'+lessonId+'/comment/'+comment.id+'/like'
    }
    dispatch('sendLikeRequest', { data: data, isLiked: comment.liked });
    let comments = getters.getCurrentLessonComments
    let newComments = JSON.parse(JSON.stringify(comments));

    let parentComment = parentId
      ? newComments.find(item => item.id === parentId)
      : newComments.find(item => item.id === comment.id);

    if (!parentComment) {
      return;
    }

    if (parentId) {
      let reply = parentComment.replies.find(reply => reply.id === comment.id);
      if (reply) {
        reply.liked = !reply.liked;
      }
    } else {
      parentComment.liked = !parentComment.liked;
    }
    commit('setResetCurrentLessonComments', newComments);

  },
  sendLikeRequest: ({ commit }, { data, isLiked }) => {
    const serviceCourse = CourseService.build();
    if (isLiked) {
      serviceCourse
        .destroy(data)
        .catch(() => { commit('setResetCurrentLessonComments', comments) })
    } else {
      serviceCourse
        .updateId(data)
        .catch(() => { commit('setResetCurrentLessonComments', comments) })
    }
  },
  loadMemberPlan: ({ commit }) => {
      const serviceMember = MemberService.build();
      serviceMember
        .read(`/member-plan`)
        .then(res => commit('setMemberPlanStatus', res.status))
        .catch(err => console.error(err))
  },
  setIsMobile: ({ commit }, isMobile) => {
    commit('setIsMobile', !!isMobile);
  }
}